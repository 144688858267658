.chips-input-listing {
  margin: 5px 0;

  .chips-input-item {
    margin: 0 5px 3px 0;
    padding: 0.3rem 0.4rem 0.3rem 0.6rem;
    border-radius: 0.3rem;
    font-weight: 500;
    letter-spacing: 0.04em;
    font-size: 0.65rem;
    color: #fff;
    white-space: break-spaces;
    word-break: break-all;
    text-align: left;

    .chips-input-remove {
      padding: 0.02rem 0.035rem 0.015rem;
      margin: 0 0 0 5px;

      color: #111111;
      font-size: 0.55rem;
      line-height: 1.3;

      &:hover {
        color: #fff;
      }

      &:focus {
        box-shadow: none;
      }
    }
  }
}
