.nav-item {
  .nav-link {
    display: flex;
    align-items: center;

    &.active {
      color: #fff;
      background: rgba(255, 255, 255, .23);
      opacity: 1;
    }
  }
}

.sidebar .nav a {
  transition: all 150ms ease-in;
}

.card-header {
  text-transform: uppercase;
}

.badge:empty {
  display: inline-block;
}

//override react-widgets date time picker button css
//apply hover css on child button, not parent span tag
.datetime-picker {
  .rw-select.rw-select-bordered {
    .rw-btn {
      &:hover,
      &:active {
        background-color: #e6e6e6
      }

      &:active {
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125)
      }
    }

    .rw-btn:first-child {
      border-right: #ccc 1px solid;
    }
  }

  //remove original css on parent span tag
  .rw-select-bordered:hover,
  .rw-select-bordered:active {
    background-color: unset;
  }

  .rw-select-bordered:active {
    box-shadow: unset
  }
}