// override bootstrap 4 variables

// $blue: #2196f3;
// $indigo: #536dfe;
// $purple: #9c27b0;
// $pink: #ff4081;
// $red: #f44336;
// $orange: #ff9800;
// $yellow: #ffeb3b;
// $green: #4caf50;
// $teal: #009688;
// $cyan: #00bcd4;
// $white: #fff;
$theme-colors: (
  "primary": #6a82fb,
  "secondary": #fc5c7d,
  "success": #45b649,
  "danger": #f85032,
  "warning": #ffd700,
  "info": #00c9ff
);

$enable-gradients: true;

// card
$card-cap-bg: #fff;


// sidebar
$cr-sidebar-width: 224px;

// media queries
$screen-mobile-min: 1px;
$screen-mobile-max: 599px;

$screen-small-tablet-min: 600px;
$screen-small-tablet-max: 767px;

$screen-tablet-min: 768px;
$screen-tablet-max: 991px;

$screen-laptop-min: 992px;
$screen-laptop-max: 1199px;

$screen-desktop-min: 1200px;
