.cr-search-form {
  position: relative;

  &__icon-search {
    position: absolute;
    left: 8px;
  }

  &__input {
    /* stylelint-disable-next-line */
    padding-left: 35px !important;
  }
}

[data-reach-combobox-option] {
  font-weight: bold;
}

[data-suggested-value] {
  font-weight: normal;
}